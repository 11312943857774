<template>
  <UnitPreviewInfo :unit-id="$route.params.unitID"
                   #default="{unitType}">
    <el-row>
      <el-col :lg="{span: 10, offset: 7}">
        <el-card class="overflow-visible" v-loading.body="isLoading">
          <template #header>
            <div>
              <h2 class="heading page-heading">
                {{ $t(`page_adoption_edit.${unitType}.title`) }}
              </h2>
              <CurrentUnitInfo />
            </div>
          </template>
          <el-form
              label-position="top"
              :rules="formRules"
              size="medium"
              status-icon
              :model="form"
              ref="form"
          >
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                    prop="children"
                    :label="$t(`page_adoption_edit.${unitType}.fields.children`)"
                >
                  <el-input v-model.number="form.children"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    prop="monthlyCost"
                    :label="$t(`page_adoption_edit.${unitType}.fields.monthlyCost`)"
                >
                  <el-input v-model.number="form.monthlyCost"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                    prop="content"
                    :label="$t(`page_adoption_edit.${unitType}.fields.content`)"
                >
                  <v-text-editor :allow-media-files="false" v-model="form.content"></v-text-editor>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <el-button type="primary" @click="handleUpdateAdoption">
                  {{ $t('page_adoption_edit.btn_submit') }}
                </el-button>
                <el-button @click="goToUnitPage">
                  {{ $t('page_adoption_edit.btn_cancel') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </UnitPreviewInfo>
</template>

<script>
import {
  fetchAdoptionParameters,
  updateAdoptionParameters
} from '../shared/services/purpose-service'
import {numberValueValidator} from '../../../shared/validators/numberValueValidator'
import {positiveNumberValidator} from '../../../shared/validators/positiveNumberValidator'
import {mapState} from 'vuex'

/*
*
* todo: create unitStore or someth to share unit data such as unitType
* reason: UnitPreviewInfo creates the same api request as CurrentUnitInfo do
*
*/

import {
  UNIT_PURPOSES_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'
import VTextEditor from '../../../components/ui/VTextEditor'
import CurrentUnitInfo from '../components/current-unit-info/CurrentUnitInfo'
import UnitPreviewInfo from '../../../components/units/UnitPreviewInfo'

export default {
  name: 'UnitAdoptionEdit',
  components: {CurrentUnitInfo, VTextEditor, UnitPreviewInfo},
  data() {
    return {
      form: {
        monthlyCost: 0,
        content: '',
        children: 0
      },
      formRules: {
        monthlyCost: [
          {required: true, message: this.$t('validators.field_required'),},
          {validator: numberValueValidator, trigger: 'change'},
          {validator: positiveNumberValidator, trigger: 'change'}
        ],
        children: [
          {required: true, message: this.$t('validators.field_required'),},
          {validator: numberValueValidator, trigger: 'change'},
          {validator: positiveNumberValidator, trigger: 'change'}
        ],
        content: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
      }
    }
  },
  created() {
    this.fetchAdoption()
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    })
  },
  methods: {
    goToUnitPage() {
      this.$router.push({
        name: UNIT_PURPOSES_ROUTE_NAME,
        params: {
          unitID: this.$route.params.unitID
        }
      })
    },
    fetchAdoption() {
      const {adoptionID} = this.$route.params
      fetchAdoptionParameters(adoptionID)
          .then((res) => {
            this.form = {
              ...res
            }
          })
          .catch((e) => {
            this.$message({
              type: 'error',
              message: e.data
            })
          })
    },
    handleUpdateAdoption() {
      this.$refs.form.validate((valid) => {
        if (!valid) return

        const {adoptionID} = this.$route.params
        const {
          children,
          monthlyCost,
          content
        } = this.form

        updateAdoptionParameters({
          adoptionID,
          children,
          monthlyCost,
          content
        })
            .then(() => {
              this.goToUnitPage()
              this.$message({
                message: `${this.$t('page_adoption_edit.messages.update_success')}`,
                type: 'success'
              })
            })
            .catch((e) => {
              this.$message({
                message: `${this.$t(`page_adoption_edit.messages.${e.data}`)}`,
                type: 'error'
              })
            })
      })
    }
  }
}
</script>

<style scoped>

</style>
